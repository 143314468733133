import whenDomReady from 'when-dom-ready';

async function initialise() {
  if ('objectFit' in document.documentElement.style === false) {
    const videos = document.querySelectorAll('.js-video');

    if (videos && videos.length) {
      const { default: objectFitVideos } = await import('object-fit-videos');
      objectFitVideos(videos);
    }
  }
}

whenDomReady().then(initialise);
